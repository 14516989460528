@font-face {
font-family: '__urwDinLight_51fc40';
src: url(/_next/static/media/b47030c719130b5f-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__urwDinLight_Fallback_51fc40';src: local("Arial");ascent-override: 108.02%;descent-override: 25.60%;line-gap-override: 0.00%;size-adjust: 97.67%
}.__className_51fc40 {font-family: '__urwDinLight_51fc40', '__urwDinLight_Fallback_51fc40', system-ui, arial
}.__variable_51fc40 {--URW-Din-Light: '__urwDinLight_51fc40', '__urwDinLight_Fallback_51fc40', system-ui, arial
}

@font-face {
font-family: '__urwDinMedium_05e558';
src: url(/_next/static/media/c03e1ba88e6d647f-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__urwDinMedium_Fallback_05e558';src: local("Arial");ascent-override: 106.66%;descent-override: 25.27%;line-gap-override: 0.00%;size-adjust: 98.91%
}.__className_05e558 {font-family: '__urwDinMedium_05e558', '__urwDinMedium_Fallback_05e558', system-ui, arial
}.__variable_05e558 {--URW-Din-Medium: '__urwDinMedium_05e558', '__urwDinMedium_Fallback_05e558', system-ui, arial
}

@font-face {
font-family: '__urwDinBold_0cd786';
src: url(/_next/static/media/bd1303090de1c588-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__urwDinBold_Fallback_0cd786';src: local("Arial");ascent-override: 103.96%;descent-override: 24.64%;line-gap-override: 0.00%;size-adjust: 101.48%
}.__className_0cd786 {font-family: '__urwDinBold_0cd786', '__urwDinBold_Fallback_0cd786', system-ui, arial
}.__variable_0cd786 {--URW-Din-Bold: '__urwDinBold_0cd786', '__urwDinBold_Fallback_0cd786', system-ui, arial
}

